<template>
  <div class="container-page">
    <p class="title-inner">Вопросы и ответы</p>
    <div class="mb-16">
      <ButtonStock title="Создать" @click="editCard(0)" />
    </div>
    <div class="mb-4">
      <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
    </div>
    <div>
      <div class="table-container mb-16 js-table-arrow">
        <table class="stockTable">
          <thead>
            <tr>
              <th>№ п/п</th>
              <th>Текст вопроса</th>
              <th>Опубликован</th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th class="filter"></th>
              <th class="filter">
                <FilterInput
                  placeholder="Текст вопроса"
                  v-model="filter.questionText"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  :select2Options="yesNoSelectList"
                  placeholder="Выберите"
                  type="select2"
                  v-model="filter.isPublished"
                  :select2Settings="select2Settings"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="page.items?.length && !isLoading">
            <tr v-for="item in page.items" :key="item.id" @dblclick="editCard(item.id)">
              <td>{{ item.order }}</td>
              <td>{{ item.questionText }}</td>
              <td>{{ item.isPublished ? 'Да' : 'Нет' }}</td>
              <td>
                <IconComponent @click="editCard(item.id)" name="mode" />
              </td>
              <td>
                <IconComponent @click="deleteCard(item.id)" name="delete" />
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="isLoading">
            <tr>
              <td class="text-center" colspan="16">Загрузка...</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="16">Нет данных.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="page.pagesTotal > 1">
        <Pagination
          :page-count="page.pagesTotal"
          :current-page="page.pageNumber"
          @change="onPageChange($event, false)"
          @more="onPageChange($event, true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import FilterInput from '@/common/components/FilterInput';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';

  import IconComponent from '../../../common/ui/IconComponent';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import Api from '../api/question';

  export default {
    name: 'Questions',
    components: { IconComponent, ButtonStock, FilterInput, DefaultFilter },
    mixins: [filtermanager],
    data() {
      return {
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          questionText: '',
          isPublished: null,
        },

        isLoading: false,
        yesNoSelectList: [
          { id: null, text: 'Выберите', selected: true },
          { id: true, text: 'да', selected: true },
          { id: false, text: 'нет', selected: true },
        ],
        select2Settings: Constants.select2Settings,
      };
    },
    created() {
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        this.loadPage(false);
      });
    },
    methods: {
      loadPage(append) {
        var params = Object.assign(this.filter, this.request);
        this.isLoading = !append;
        Api.search(params)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      editCard(id) {
        this.$router.push({ name: 'QuestionCard', params: { id: id, action: 'edit' } });
      },
      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            Api.delete(id)
              .then((response) => {
                console.log(response);
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена.',
                  'error',
                );
              });
          }
        });
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
    },
  };
</script>

<style lang="scss">
  .question__filter-check input[type='checkbox'] {
    position: relative !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
  }
</style>
